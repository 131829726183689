<script>
import PrimaryInformation from "@/components/Objects/view/elements/PrimaryInformation";
import BaseArrowLeftIcon from "@/components/icons/BaseArrowLeftIcon";
import BasePrintIcon from "@/components/icons/BasePrintIcon";
import BaseButton from "@/components/Reusable/BaseButton";
import BaseMinusCircleIcon from "@/components/icons/BaseMinusCircleIcon";
import BaseLoading from "@/components/Reusable/BaseLoading";
import BaseEyeIcon from "@/components/icons/BaseEyeIcon";
import { formatToPrice } from "@/util/reusable";
import { mapGetters, mapMutations } from "vuex";
import api from "@/services/api";
import PdfTemplate from "@/components/PdfTemplate2";
import ApartmentComments from "@/components/Objects/view/elements/ApartmentComments";
import { XIcon } from "@/components/ui-components/material-icons";
import { isNUNEZ } from "@/util/inspect";
import HidePriceButton from "@/components/Reusable/HidePriceButton.vue";
import HidePromoButton from "@/components/Reusable/HidePromoButton.vue";
import Permission from "@/permission";
import { v3ServiceApi } from "@/services/v3/v3.service";

export default {
  name: "ApartmentExpressView",

  /* COMPONENTS */
  components: {
    HidePriceButton,
    HidePromoButton,
    BaseButton,
    BasePrintIcon,
    BaseLoading,
    BaseArrowLeftIcon,
    PrimaryInformation,
    BaseMinusCircleIcon,
    BaseEyeIcon,
    PdfTemplate,
    ApartmentComments,
    XIcon,
  },

  /* PROPS */
  props: {
    apartment: {
      type: Object,
      default: () => ({}),
    },
    visible: {
      type: Boolean,
      default: false,
    },
    apartmentUuid: {
      type: String,
      default: "",
    },
  },

  /* EMITS */
  emits: ["hide-apartment-sidebar-view", "update-content"],

  /* DATA */
  data() {
    const apartmentCommentsPermission = {
      view: Permission.getUserPermission("apartments.comments.view"),
      create: Permission.getUserPermission("apartments.comments.create"),
      edit: Permission.getUserPermission("apartments.comments.edit"),
      delete: Permission.getUserPermission("apartments.comments.delete"),
    };
    return {
      reservation: {
        client: {
          first_name: null,
          last_name: null,
          phone: null,
          period_date: null,
          language: "uz",
        },

        error: false,
        errors: [],

        header: {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        },

        getLoading: false,
      },
      isDownloading: false,
      imgDataUrl: "",
      apartmentCommentsPermission,
      htmlToPdfOptions: {
        margin: 6,
        filename: "",
      },
      tabList() {
        return [
          {
            name: "Успешные",
            status: "success",
            counts: 2,
          },
          {
            name: "Ошибка загрузки",
            status: "failed",
            counts: 3,
          },
        ];
      },
      sidebarApartment: {},
      appLoading: true,
      variant: "none",
      visibleModal: true,
      showReservationModal: false,
      printCalc: {},
      commentsData: {},
      commentLoading: false,
      editDatePermission: Permission.getUserPermission("checkout.edit_date"),
      bookPermission: Permission.getUserPermission("checkout.book"),
      checkoutPermission: Permission.getUserPermission("checkout.checkout"),
      checkoutRootPermission: Permission.getUserPermission("checkout.root"),
      statusViewPms: Permission.getUserPermission(
        "settings.apartments.status.view"
      ),
      holderViewPms: Permission.getUserPermission(
        "settings.apartments.holder.view"
      ),
    };
  },

  /* COMPUTED */
  computed: {
    ...mapGetters({
      me: "getMe",
      reserveClient: "getReserveClient",
    }),
    visibleComp: {
      get() {
        return this.visible;
      },
      set(value) {
        if (value) {
          this.fetchSidebarItem();
          if (
            this.apartmentCommentsPermission &&
            this.apartmentCommentsPermission.view
          ) {
            this.getComments();
          }
        } else {
          this.$emit("hide-apartment-sidebar-view");
        }
      },
    },
    hasApartment() {
      return Object.keys(this.sidebarApartment).length > 0;
    },
    price() {
      return `${formatToPrice(this.sidebarApartment.price, 2)} ${this.$t(
        "ye"
      )}`;
    },
    squareMetrePrice() {
      return `${formatToPrice(this.sidebarApartment.price_m2)} ${this.$t(
        "ye"
      )}`;
    },
    status() {
      if (!this.sidebarApartment.is_sold) {
        return "unavailable";
      }
      return this.sidebarApartment.order.status;
    },

    permission() {
      const context = {
        cancelReserve: false,
        reserve: false,
        continueOrder: false,
        order: false,
        contract: false,
      };

      if (!this.hasApartment) return context;

      const { sidebarApartment, me } = this;
      const { order } = sidebarApartment;
      // const {checkout} = userPermission
      const forSale = sidebarApartment.is_sold;
      const authorityUser = order?.user?.id === me?.user?.id;
      // const rootContract = userPermission?.checkout?.root
      // const isMainRole = me?.role?.id === 1
      const isStatusBooked = order.status === "booked";
      const isStatusAvailable = order.status === "available";
      const isStatusHold = order.status === "hold";
      const isStatusSold = order.status === "sold";
      const isStatusClosed = order.status === "closed";
      const isStatusContract = order.status === "contract";

      const permissionCancelReserve =
        isStatusBooked && (authorityUser || this.checkoutRootPermission);
      const permissionReserve =
        forSale && isStatusAvailable && this.bookPermission;

      const permissionContract = () => {
        const permissionOne = this.checkoutPermission && authorityUser;
        return (
          (isStatusSold || isStatusContract || isStatusClosed) &&
          (permissionOne || this.checkoutRootPermission)
        );
      };

      const permissionOrder = () => {
        const permissionOne =
          isStatusAvailable &&
          (authorityUser ||
            this.checkoutPermission ||
            this.checkoutRootPermission);
        return forSale && permissionOne;
      };
      const permissionContinueOrder = () => {
        const permissionOne =
          isStatusHold &&
          (authorityUser ||
            this.checkoutRootPermission ||
            this.checkoutPermission);
        const permissionTwo =
          isStatusBooked && authorityUser && this.checkoutPermission;
        return permissionOne || permissionTwo;
      };

      const effectContext = (property) => {
        context[property] = true;
      };

      permissionCancelReserve && effectContext("cancelReserve");
      permissionReserve && effectContext("reserve");
      permissionOrder() && effectContext("order");
      permissionContinueOrder() && effectContext("continueOrder");
      permissionContract() && effectContext("contract");

      return context;
    },
  },
  watch: {
    sidebarApartment() {
      this.imgDataUrl = "";
      if (this.sidebarApartment?.plan?.images) {
        this.toDataUrl(this.sidebarApartment.plan.images[0])
          .then((url) => {
            this.imgDataUrl = url;
          })
          .catch((er) => console.log("error", er));
      }
    },
  },

  /* METHODS */
  methods: {
    // RESERVATION
    closeModal() {
      this.$nextTick(() => {
        this.$refs["modal-reserve-createee"].hide();
      });
    },
    resetModal() {
      this.reservation.client.first_name = null;
      this.reservation.client.last_name = null;
      this.reservation.client.phone = null;
      this.reservation.client.period_date = null;
      this.reservation.error = false;
      this.reservation.errors = [];
    },

    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },

    async handleSubmit() {
      this.reservation.getLoading = true;
      try {
        this.reservation.client.apartments = [this.sidebarApartment.uuid];
        const response = await api.apartments.bookingApartments(
          this.reservation.client
        );
        this.toasted(response.data.message, "success");
        this.$refs["modal-reserve-createee"].hide();
        this.reservation.getLoading = false;
        this.$emit("CreateReserve", this.reservation.client);
        // const {contract_path} = response.data
        // this.downloadContract(contract_path)
      } catch (error) {
        this.reservation.getLoading = false;
        if (!error.response) {
          this.toasted("Error: Network Error", "error");
        } else if (error.response.status === 403) {
          this.toasted(error.response.data.message, "error");
        } else if (error.response.status === 422) {
          this.error = true;
          this.errors = error.response.data;
        } else {
          this.error = true;
          this.errors = error.response.data;
        }
      }
    },
    downloadContract(url) {
      const a = document.createElement("a");
      a.href = url;
      a.click();
      document.body.removeChild(a);
    },
    // RESERVATION

    completePrintingProcess() {
      this.isDownloading = false;
    },
    async toDataUrl(url) {
      // Convert to base64
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
          const reader = new FileReader();
          reader.onloadend = function () {
            resolve(reader.result);
          };
          reader.readAsDataURL(xhr.response);
        };
        xhr.onerror = () => {
          reject({
            status: this.status,
            statusText: xhr.statusText,
          });
        };
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.send();
      });
    },
    isNUNEZ,
    ...mapMutations(["setCalculationProperties"]),
    async getComments() {
      const { object } = this.$route.params;
      this.commentLoading = true;
      await api.apartmentsV2
        .getApartmentComments(object, this.apartment.uuid)
        .then((res) => {
          this.commentsData = res.data;
        })
        .catch((err) => {
          this.toasted(err.message, "error");
        })
        .finally(() => {
          this.commentLoading = false;
        });
    },
    printPdf() {
      this.pdfVisible = true;
      this.$refs.html2Pdf.generatePdf();
    },
    getCalc(value) {
      this.printCalc = value;
      this.setCalculationProperties(value);
    },
    changeTabOfUploadList(status) {
      const { status: queryStatus } = this.$route.query;
      if (queryStatus !== status) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            status,
          },
        });
      }
    },
    viewMore() {
      this.$router.push({
        name: "apartment-view",
        params: {
          object: this.sidebarApartment.object.id,
          id: this.apartment.uuid,
        },
      });
    },
    async fetchSidebarItem() {
      this.appLoading = true;
      const { object } = this.$route.params;
      await api.apartmentsV2
        .getApartmentView(object, this.apartmentUuid)
        .then((response) => {
          this.sidebarApartment = response.data;
        })
        .catch((error) => {
          this.toastedWithErrorCode(error);
        })
        .finally(() => {
          this.appLoading = false;
        });
    },
    hideApartmentSidebar() {
      this.$emit("hide-apartment-sidebar-view");
    },
    printApartmentInformation() {
      this.isDownloading = true;
      const { object, block, entrance, number } = this.sidebarApartment;
      this.htmlToPdfOptions.filename = `${object.name} , ${block.name} , ${entrance}/${number}`;
      this.$refs.html2Pdf.generatePdf();
    },
    async orderApartment() {
      this.appLoading = true;
      try {
        const apartments = [this.sidebarApartment.id];
        const { data } = await api.orders.holdOrder(apartments, "apartment");
        // await v3ServiceApi.holdLogs.create({apartment_id: this.sidebarApartment.id})
        if (data) {
          const objectId = data.orders[0].apartment.object.id;
          await this.$router.push({
            name: "checkout-new",
            params: {
              id: data.uuid,
              object: objectId,
            },
          });
          /* await this.$router.push({
            name: "checkout",
            params: {
              id: data.uuid
            }
          }) */
        }
      } catch (e) {
        this.toastedWithErrorCode(e);
      } finally {
        this.appLoading = false;
      }
    },
    continueApartmentOrder() {
      this.$router.push({
        name: "checkout-new",
        params: {
          id: this.sidebarApartment.order.id,
          object: this.sidebarApartment.object.id,
        },
      });
    },
    updateContent() {
      this.$emit("update-content");
      this.fetchSidebarItem();
      this.getComments();
    },
    holderTooltipTitle(holder) {
      let title = "";
      if (holder?.last_name && holder.last_name.trim() !== "") {
        title += holder.last_name;
      }

      if (holder?.first_name && holder.first_name.trim() !== "") {
        title += ` ${holder.first_name}`;
      }

      if (holder?.middle_name && holder.middle_name.trim() !== "") {
        title += ` ${holder.middle_name}`;
      }

      return title.trim();
    },
    async cancelReservation() {
      this.appLoading = true;
      await api.orders
        .fetchOrderClient(this.sidebarApartment.order.id)
        .then((response) => {
          const status = response.data;
          this.$swal({
            title: this.$t("sweetAlert.title"),
            text: this.$t("sweetAlert.text_cancel_reserve"),
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: this.$t("cancel"),
            confirmButtonText: this.$t("sweetAlert.yes_cancel_reserve"),
          }).then((result) => {
            if (result.value) {
              this.appLoading = true;
              api.orders
                .deactivateReserveOrders(client.id)
                .then((response) => {
                  this.toasted(response.data.message, "success");

                  this.$nextTick(() => {
                    this.$bvModal.hide("modal-view-reserved-client");
                  });

                  this.hideApartmentSidebar();
                  this.updateContent();

                  this.$swal(
                    this.$t("sweetAlert.canceled_reserve"),
                    "",
                    "success"
                  );
                })
                .catch((error) => {
                  this.toastedWithErrorCode(error);
                })
                .finally(() => {
                  this.appLoading = false;
                });
            }
          });
        })
        .catch((error) => {
          this.toastedWithErrorCode(error);
        })
        .finally(() => {
          this.appLoading = false;
        });
    },
  },
};
</script>

<template>
  <b-sidebar
    id="apartment-express-view"
    v-model="visibleComp"
    right
    shadow
    backdrop
    no-header
    sidebar-class="sidebar__apartment"
    body-class="sidebar__apartment-body"
    aria-labelledby="sidebar-no-header-title"
    :backdrop-variant="variant"
  >
    <template #default="{}">
      <section v-if="hasApartment && !appLoading">
        <!--  HEAD    -->
        <div
          class="head d-flex justify-content-between align-items-center pdf-item"
        >
          <span class="d-flex justify-content-center align-items-center">
            <span
              class="close__button d-flex justify-content-center align-items-center"
              @click="hideApartmentSidebar"
            >
              <base-arrow-left-icon :width="32" :height="32" />
            </span>
            <span class="section__title">
              {{ sidebarApartment.object.name }} ,
              {{ sidebarApartment.block.name }}
            </span>
          </span>
          <span
            v-if="holderViewPms && isNUNEZ(sidebarApartment.holder)"
            class="ml-2 mr-2 cursor-pointer"
          >
            <x-icon
              v-b-tooltip.hover
              size="32"
              name="person"
              class="light-blue-500"
              :title="holderTooltipTitle(sidebarApartment.holder)"
            />
          </span>
          <div class="d-flex" style="gap: 10px">
            <hide-price-button />
            <hide-promo-button />
          </div>
        </div>

        <div class="d-flex w-100 justify-content-end">
          <div class="d-flex" style="font-size: 12px">
            <span
              v-if="statusViewPms && isNUNEZ(sidebarApartment.status)"
              style="
                display: inline-flex;
                height: 40px;
                padding: 0 20px;
                justify-content: center;
                align-items: center;
                gap: 6px;
                flex-shrink: 0;
                color: white;
                border-radius: 28px;
                white-space: nowrap;
              "
              :style="{
                'background-color': sidebarApartment.status.color,
                color:
                  sidebarApartment.status.color === '#ffffff'
                    ? '#000000'
                    : '#ffffff',
                'border-radius':
                  statusViewPms && isNUNEZ(sidebarApartment.status)
                    ? '28px 0 0 28px'
                    : '28px',
              }"
            >
              {{ sidebarApartment.status.title[$i18n.locale] }}
            </span>
            <span
              class="apartment__status d-flex justify-content-center align-items-center"
              :class="`status-${status}`"
              style="
                display: inline-flex;
                height: 40px;
                padding: 0 20px;
                justify-content: center;
                align-items: center;
                gap: 6px;
                flex-shrink: 0;
                border-radius: 28px;
                white-space: nowrap;
              "
              :style="{
                'border-radius':
                  statusViewPms && isNUNEZ(sidebarApartment.status)
                    ? '0 28px 28px 0'
                    : '28px',
              }"
            >
              {{ $t(`apartments.status.${status}`) }}
            </span>
          </div>
        </div>

        <!--  MAIN    -->
        <primary-information
          class="pdf-item"
          :apartment="sidebarApartment"
          @for-print="getCalc"
        />

        <apartment-comments
          v-if="apartmentCommentsPermission.view"
          :comments-data="commentsData"
          :comment-loading="commentLoading"
          :express-view="true"
          :permissions="apartmentCommentsPermission"
          :apartment-uuid="apartmentUuid"
          @update-comments="getComments"
        />

        <!--   ACTIONS     -->
        <div class="action-block">
          <!-- VIEW MORE-->
          <router-link
            :to="{
              name: 'apartment-view',
              params: {
                object: sidebarApartment.object.id,
                id: apartment.uuid,
              },
            }"
          >
            <base-button
              id="learnMore"
              :text="`${$t('more_info')}`"
              class="violet-gradient"
            >
              <template #left-icon>
                <base-eye-icon :square="20" fill="#fff" />
              </template>
            </base-button>
          </router-link>

          <b-tooltip target="learnMore" triggers="hover">
            <p class="tooltip-text">
              {{ $t("more_info") }}
            </p>
          </b-tooltip>

          <!--      CHECKOUT        -->
          <base-button
            v-if="permission.order"
            :text="`${$t('apartments.list.confirm')}`"
            design="violet-gradient"
            @click="orderApartment"
          />

          <!--      CONTINUE CHECKOUT        -->
          <base-button
            v-if="permission.continueOrder"
            :text="`${$t('continue_registration')}`"
            class="checkout__button violet-gradient"
            @click="continueApartmentOrder"
          />

          <!--       MAKE A RESERVATION       -->
          <base-button
            v-if="permission.reserve"
            :text="`${$t('apartments.list.book')}`"
            @click="() => $refs['modal-reserve-createee'].show()"
          >
            <template #left-icon>
              <base-minus-circle-icon :square="20" fill="#4B5563" />
            </template>
          </base-button>

          <!-- CANCEL RESERVE -->
          <base-button
            v-if="permission.cancelReserve"
            :text="`${$t('apartments.list.cancel_reserve')}`"
            class="reserve__button"
            @click="cancelReservation"
          />

          <!--     CONTRACT VIEW         -->
          <router-link
            v-if="permission.contract"
            :to="{
              name: 'contracts-view',
              params: { id: sidebarApartment.order.id },
            }"
          >
            <base-button
              design="violet-gradient px-5"
              :text="`${$t('apartments.list.contract')}`"
            />
          </router-link>

          <!--PRINT-->
          <button
            id="print"
            :disabled="isDownloading"
            class="print__button bg-gray-100 d-flex justify-content-center align-items-center"
            @click="printApartmentInformation"
          >
            <base-loading v-if="isDownloading" :height="40" />
            <base-print-icon v-else :square="20" fill="#4B5563" />
          </button>
          <b-tooltip target="print" triggers="hover">
            <p class="tooltip-text">
              {{ $t("apartments.view.print") }}
            </p>
          </b-tooltip>
        </div>
      </section>

      <!--  MAKE A RESERVATION MODAL    -->
      <div>
        <!--    confirm-apartment-->
        <b-modal
          id="modal-reserve-createee"
          ref="modal-reserve-createee"
          :title="$t('apartments.list.book')"
          hide-footer
          @show="resetModal"
        >
          <b-alert v-if="reservation.error" show variant="danger">
            <ul>
              <li v-for="(error, index) in reservation.errors" :key="index">
                <span v-for="msg in error" :key="msg">
                  {{ msg }}
                </span>
              </li>
            </ul>
          </b-alert>

          <form ref="form" @submit.prevent="handleSubmit">
            <b-form-group
              label-cols="4"
              label-cols-lg="2"
              :label="$t('user.first_name')"
              label-for="first_name"
            >
              <b-form-input
                id="first_name"
                v-model="reservation.client.first_name"
              />
            </b-form-group>

            <b-form-group
              label-cols="4"
              label-cols-lg="2"
              :label="$t('user.last_name')"
              label-for="last_name"
            >
              <b-form-input
                id="last_name"
                v-model="reservation.client.last_name"
              />
            </b-form-group>

            <b-form-group
              label-cols="4"
              label-cols-lg="2"
              :label="$t('user.phone')"
              label-for="phone"
            >
              <b-form-input id="phone" v-model="reservation.client.phone" />
            </b-form-group>

            <div role="group" class="form-row form-group">
              <label for="language" class="col-lg-2 col-4 col-form-label">
                {{ $t("clients.language") }}
              </label>
              <div class="bv-no-focus-ring col">
                <select
                  id="language"
                  v-model="reservation.client.language"
                  class="form-control"
                >
                  <option value="uz">Узбекский</option>
                  <option value="ru">Русский</option>
                </select>
              </div>
            </div>

            <b-form-group
              label-cols="4"
              label-cols-lg="4"
              :label="$t('apartments.list.period_date')"
              label-for="period_date"
            >
              <b-form-datepicker
                v-model="reservation.client.period_date"
                locale="ru"
              />
            </b-form-group>

            <div class="w-100 d-flex justify-content-center">
              <b-button
                type="button"
                variant="light"
                @click="$refs['modal-reserve-createee'].hide()"
              >
                {{ $t("cancel") }}
              </b-button>

              <b-button type="submit" class="ml-1 mr-0" variant="success">
                <b-icon-box-arrow-up-right />
                {{ $t("apartments.list.book") }}
              </b-button>
            </div>
          </form>
        </b-modal>

        <b-overlay
          :show="reservation.getLoading"
          no-wrap
          opacity="0.5"
          style="z-index: 2222"
        >
          <template #overlay>
            <div class="d-flex justify-content-center w-100">
              <div class="lds-ellipsis">
                <div />
                <div />
                <div />
                <div />
              </div>
            </div>
          </template>
        </b-overlay>
      </div>
      <PdfTemplate
        v-if="visible && Object.keys(sidebarApartment).length"
        ref="html2Pdf"
        :apartment="sidebarApartment"
        :print-calc="printCalc"
        :img-data="imgDataUrl"
        @has-downloaded="completePrintingProcess"
      />

      <!--  LOADING    -->
      <base-loading v-if="appLoading" class="h-100" />
    </template>
  </b-sidebar>
</template>

<style lang="sass" scoped>
::v-deep .b-sidebar
  filter: drop-shadow(-16px 0px 64px rgba(0, 0, 0, 0.16)) drop-shadow(-16px 0px 24px rgba(0, 0, 0, 0.06))

  &.b-sidebar-right
    border-radius: 56px 0 0 56px

  & > .b-sidebar-body
    border-radius: 1rem

::v-deep .sidebar__apartment
  width: 42rem
  font-family: CraftworkSans, serif

  &-body
    //margin: 0 3.5rem
    //padding: 3.5rem 0
    padding: 3.5rem
    -ms-overflow-style: none
    scrollbar-width: none

    &::-webkit-scrollbar
      display: none

    .head
      line-height: 1.75rem

      .close__button
        min-width: 3.5rem
        width: 3.5rem
        height: 3.5rem
        cursor: pointer
        border-radius: 50%
        margin-right: 0.5rem
        background-color: var(--gray-100)
        transition: background-color 100ms ease-in-out

        &:hover
          background-color: var(--gray-200)

      .section__title
        color: var(--gray-600)
        font-size: 1.5rem

      .apartment__status
        font-family: Inter, sans-serif
        background-color: var(--gray-100)
        border-radius: 2rem
        min-width: max-content
        padding: 0.5rem 2rem

    .vue-html2pdf
      .layout-container
        position: relative
        width: auto
        height: auto
        left: 0
        z-index: 0
        background: #FFFFFF
        display: block

//.checkout__button
//padding: 1rem 3rem

.action-block
  display: flex
  flex-wrap: wrap
  margin-top: 1rem
  margin-bottom: .5rem
  gap: .5rem

.print__button,
.cancel__button,
.view__button
  outline: none
  border: none
  width: 3.5rem
  height: 3.5rem
  border-radius: 50%

.contract_view_btn
  height: 3.5rem
  border-radius: 2rem
  padding: 1rem 2rem
  background-color: var(--blue-600)

.status
  &-waiting
    background-color: var(--yellow-100) !important
    color: var(--yellow-600) !important

  &-contract
    background-color: var(--blue-100) !important
    color: var(--blue-600) !important

  &-booked
    background-color: var(--yellow-100) !important
    color: var(--yellow-600) !important

  &-cancelled
    background-color: var(--pink-100) !important
    color: var(--pink-600) !important

  &-available
    background-color: var(--teal-100) !important
    color: var(--teal-600) !important

  &-sold
    background-color: var(--gray-100) !important
    color: var(--gray-600) !important

  &-unavailable
    background-color: var(--gray-500) !important
    color: var(--white) !important

::v-deep .b-tooltip
  .tooltip-text
    color: #fff !important
    margin-bottom: 0
</style>
